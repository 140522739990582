import React from "react";
import styled from "@emotion/styled";
import { NavLink, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Button,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  InputLabel,
  DialogContent,
  MenuList,
  ListItemText,
  TextareaAutosize,
} from "@mui/material";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { Box, spacing } from "@mui/system";

import {
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import ModalAproveAccount from "../../components/modal-approve-account";

const Paper = styled(MuiPaper)(spacing);
const CustomTableCell = styled(TableCell)`
  &.${tableCellClasses.head} {
    background: ${(props) => props.theme.palette.common.black};
    color: ${(props) => props.theme.palette.common.white};
  }
  &.${tableCellClasses.body} {
    font-size: 13px;
  }
`;

const CustomTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.025);
  }
`;

// Data
let id = 0;
function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3)
];
function ManageApproval(props) {
  
  console.log(props)
  const { onClose, document, open,listDocumentRequestedStatus } = props;
  let { getApi,admPostApi,admGetApi } = useAuth();

  const [statusChoosed, setStatusChoosed] = React.useState(document.status["id"]);
  const [changing, setChanging] = React.useState(false);

  const handleClose = () => {
    onClose(false);
  };

  useEffect(() => {

  },[]);

  const ChangeStatus = async () => {
    if(!window.confirm("A situação do documento está correta?")){
      return ;
    }
    setChanging(true);
    let response = await admPostApi("/document/manage_approval", {
      request_documents_id:document.id,
      requested_document_status_id:statusChoosed
    });

    if(response.permission==1){
      alert("A situação do documento foi alterada com sucesso.");
      window.location.reload();
    }else{
      alert("Um erro ocorreu, é possível que o status já tenha sido atualizado.");
     window.location.reload();
    }
    setChanging(false);


  };




  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true}>
      <DialogTitle>Análise - {document.document_type["name"]}</DialogTitle>
        
      <DialogContent>
      <Grid item lg={12}>
      <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Situação do documento</InputLabel>

      <Paper sx={{marginTop:10}}>
      <MenuList>
      { listDocumentRequestedStatus.map((row) => (
         <MenuItem>
         {
          statusChoosed==row.id?
          <ListItemText sx={{backgroundColor:'#eeeeee'}} onClick={e => setStatusChoosed(row.id)} >{row.extend_name}</ListItemText>
          :
          <ListItemText onClick={e => setStatusChoosed(row.id)} >{row.extend_name}</ListItemText>
         }

         </MenuItem>
      ))}
        </MenuList>
    </Paper>
           
          </FormControl>
        </Grid>
        <br></br>
          <Button
                  variant="contained"
                  color="boot_danger"
                  size="small"
                  onClick={e=> ChangeStatus()}
                  disabled={changing}>
                  Alterar Situação
                </Button>
      </DialogContent>
    </Dialog>
  );
}


function AccordionDocumentId(props) {

  const {document,} = props;
  const [listDocumentRequestedStatus, setListDocumentRequestedStatus] = React.useState([]);
  let { getApi,admPostApi,admGetApi } = useAuth();
  const [openManageApproval, setOpenManageApproval] = React.useState(false);
  const [expandDocument, setExpandDocument] = React.useState(false);
  const [expandValidationDocument, setExpandValidationDocument] = React.useState(false);

  const [expanding, setExpanding] = React.useState(false);


  const files_sents=document.files_sents;

  useEffect(() => {
    LoadDocumentRequestedStatus();

  }, []);


  const LoadDocumentRequestedStatus = async () => {
      
    setListDocumentRequestedStatus([]);
    let response = await admGetApi("/list/document/status", {

    });
    
    setListDocumentRequestedStatus(response.lista);

  };

  const InitialApproval = async (request_documents_id) => {
   
    setExpanding(true);
    let response = await admPostApi("/document/initial_approval", {
      request_documents_id:request_documents_id
    });
    if(response.permission==1){
      setExpandValidationDocument(true);
    }else if(response.permission==2){
      setExpandValidationDocument(true);
    }else{
      setExpandValidationDocument(false);
      alert("Você não pode iniciar a aprovação deste documento")
    }
    setExpanding(false);
    
  };



  return (
    <Accordion  expanded={expandDocument}>
       {expanding && 
     <span className="text-warning"> Carregando dados....</span>}
    <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={e=> setExpandDocument(!expandDocument) }>
   
      <Typography><strong>{document.status["name"]}</strong> -  Enviado em: {new Date(document.date_doc_sent).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}  
      <br></br>
      Última Atualização {new Date(document.updated_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})} 
      
       </Typography>
      
      
    
    </AccordionSummary>
 
    <AccordionDetails>
    <Paper>
    <Table>
      {document.accountable &&
      
      <TableBody>
      

      <TableRow >
        <TableCell component="th" scope="row">
          Nome Sócio
          </TableCell>
          <TableCell component="th" scope="row">
          {document.accountable.fullname}
          </TableCell>
      </TableRow>
      <TableRow >
        <TableCell component="th" scope="row">
          CPF/CNPJ
        </TableCell>
        <TableCell component="th" scope="row">
        {document.accountable.identifier}
        </TableCell>
      </TableRow>
      <TableRow >
        <TableCell component="th" scope="row">
         Data de Nascimento
        </TableCell>
        <TableCell component="th" scope="row">
        {document.accountable.birthdate?new Date(document.accountable.birthdate).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}):null}
        </TableCell>
      </TableRow>

      <TableRow >
        <TableCell component="th" scope="row">
          Status Responsável
        </TableCell>
        <TableCell component="th" scope="row">
        {document.accountable?document.accountable.status["name"]:null}
        </TableCell>
      </TableRow>

    </TableBody>
      }
      <TableBody>
      <TableRow >
        <TableCell component="th" scope="row">
         Tipo de Documento
          </TableCell>
          <TableCell component="th" scope="row">
          {document.document_type.name}
          </TableCell>
      </TableRow>
      </TableBody>
       
      </Table>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell align="left">Data Envio</TableCell>
            <TableCell align="left">Ação</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
          files_sents.map((row) => (
            <CustomTableRow key={row.id}>
              <CustomTableCell component="th" scope="row">
              {new Date(row.created_at).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})}
              </CustomTableCell>
              <CustomTableCell align="left">
              <Link  href={row.url} target="_blank">
                <Button
                  variant="contained"
                  size="small">
                  Visualizar
                </Button>
              </Link>
              </CustomTableCell>
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
    <Box p={1} display="flex" justifyContent="center">
        <FormControl fullWidth>
        <Button variant="outlined" onClick={e=> InitialApproval(document.id)}>
        Iniciar Validação
      </Button>
      <br></br>
        <Button variant="contained" disabled={!expandValidationDocument} onClick={e=> {setOpenManageApproval(true); console.log(true)}}>
        Validar Documento
      </Button>
      {listDocumentRequestedStatus.length>0 &&
      
      <ManageApproval
      key={openManageApproval}
      document={document}
      open={openManageApproval}
      onClose={setOpenManageApproval}
      listDocumentRequestedStatus={listDocumentRequestedStatus}
    />
      }
    
        </FormControl>
          </Box>
    </AccordionDetails>
  </Accordion>
  )
}


function ListaDocumentId(props) {

  const {listDocuments,totalDocs} = props;


  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Documento de Identificação
        </Typography>
        <Typography variant="body2" gutterBottom>
         Solicitações de documentos de identificação  ( {listDocuments.length} documentos )
        </Typography>
        <div>
        {
          listDocuments.map((row) => (
          <AccordionDocumentId document={row} />
          ))}
        </div>{" "}
        {totalDocs<0 && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}

      </CardContent>
    </Card>
  );
}


function ListDocsSelfie(props) {

  const {listDocuments,totalDocs} = props;


  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Documento de Validação Facial
        </Typography>
        <Typography variant="body2" gutterBottom>
         Solicitações de documentos de Validação Facial ( {listDocuments.length} documentos )
        </Typography>
        <div>
        {
          listDocuments.map((row) => (
          <AccordionDocumentId document={row} />
          ))}
        </div>{" "}
        {totalDocs<0 && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      </CardContent>
    </Card>
  );
}


function ListDocsCNPJ(props) {

  const {listDocuments,totalDocs} = props;


  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Documento - Contrato Social
        </Typography>
        <Typography variant="body2" gutterBottom>
         Solicitações de documentos Contrato Social  ( {listDocuments.length} documentos )
        </Typography>
        <div>
        {
          listDocuments.map((row) => (
          <AccordionDocumentId document={row} />
          ))}

        </div>{" "}
        {totalDocs<0 && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      </CardContent>
    </Card>
  );
}

function ListOutrosDocs(props) {

  const {listDocuments,totalDocs} = props;


  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Documento - Outros
        </Typography>
        <Typography variant="body2" gutterBottom>
        Documentos  ( {listDocuments.length} documentos )
        </Typography>
        <div>
        {
          listDocuments.map((row) => (
          <AccordionDocumentId document={row} />
          ))}

        </div>{" "}
        {totalDocs<0 && <Grid item>
             
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      </CardContent>
    </Card>
  );
}


function Operator(props) {

  const {operator} = props;

  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
        Operador Principal
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        <Grid item md={12}>
        <Table>
        <TableBody>
          <TableRow >
            <TableCell component="th" scope="row">
              Nome
              </TableCell>
              <TableCell component="th" scope="row">
              {operator.fullname}
              </TableCell>

          </TableRow>
          <TableRow >
            <TableCell component="th" scope="row">
              E-mail
            </TableCell>
            <TableCell component="th" scope="row">
            {operator.email}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell component="th" scope="row">
             Telefone
            </TableCell>
            <TableCell component="th" scope="row">
            {operator.phone}
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Status
            </TableCell>
            <TableCell component="th" scope="row">
            {operator.status?operator.status["name"]:null}
            </TableCell>
          </TableRow>

        </TableBody>
      </Table>
        </Grid>
        {!operator.status && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      </Grid>
    </Paper>
  </Card>
  );
}



function BSAccountComponent(props) {

  const {bsAccount} = props;

  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
        Dados da Conta de Negócio
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        {bsAccount.fantasy_name &&
        <Grid item md={12}>
        <Table>
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              ID
              </TableCell>
              <TableCell component="th" scope="row">
              {bsAccount.id}
              </TableCell>

          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              CPF/CNPJ
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.identifier} ( {bsAccount.account_type?bsAccount.account_type["name"]:null})
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Razão Social
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.company_name}
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Nome Fantasia
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.fantasy_name}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell component="th" scope="row">
              Status
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.account_status?bsAccount.account_status["name"]:null}
            </TableCell>
          </TableRow>

          <TableRow >
            <TableCell component="th" scope="row">
              Atividade Principal
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.activity?bsAccount.activity["name"]:null}
            </TableCell>
          </TableRow>
          <TableRow >
            <TableCell component="th" scope="row">
              Faturamento Mensal Est.
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.monthly_billing?bsAccount.monthly_billing["name"]:null}
            </TableCell>
          </TableRow>
          <TableRow >
          <TableCell component="th" scope="row">
              PEP ( Pessoa politicamente exposta)
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.is_pep==true?"Sim":"Não"}
            </TableCell>
          </TableRow>
          <TableRow >
          <TableCell component="th" scope="row">
              Data Abertura
            </TableCell>
            <TableCell component="th" scope="row">
          {bsAccount.account_opening_date &&
          
          new Date(bsAccount.account_opening_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})
          +
          " "+
          new Date(bsAccount.account_opening_date).toLocaleTimeString('pt-br',{style: 'currency', currency: 'BRL'})
          
          
          }

          </TableCell>
          </TableRow>
          
          <TableRow >
          <TableCell component="th" scope="row">
            Data Cancelamento
            </TableCell>
            <TableCell component="th" scope="row">
          {bsAccount.account_cancellation_date &&
          
          new Date(bsAccount.account_cancellation_date).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'})
          +
          " "+
          new Date(bsAccount.account_cancellation_date).toLocaleTimeString('pt-br',{style: 'currency', currency: 'BRL'})
          
          }

            </TableCell>
          </TableRow>

          <TableRow >
          <TableCell component="th" scope="row">
             Consultor(a)
            </TableCell>
            <TableCell component="th" scope="row">
            {bsAccount.consultant}
            </TableCell>
          </TableRow>

          <TableRow >
          <TableCell component="th" scope="row">
            Observação
            </TableCell>
            <TableCell component="th" scope="row">
            <TextareaAutosize disabled={true} value={bsAccount.additional_information} minRows={3}/>
            </TableCell>
          </TableRow>
          

        
          
        </TableBody>
      </Table>
        </Grid>
        }
        {!bsAccount.fantasy_name && <Grid item>
             
                
             <Box sx={{ display: 'flex' }}>
               <CircularProgress />
             </Box>
             </Grid>}
      </Grid>
    </Paper>
  </Card>
  );
}

function BSContactsComponent({contacts}) {

console.log(contacts);
  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
      Contatos
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        <Grid item md={12} xs="12" >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Tipo</TableCell>
              <TableCell align="left">Valor</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {contacts.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.nametype}
                </TableCell>
                <TableCell align="left">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
      </Grid>
    </Paper>
  </Card>
  );
}


function BSAccountableComponent(props) {

  const {accountables} = props;
console.log(accountables);
  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
        Dados dos sócios
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        <Grid item md={12} xs="12" >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="left">CPF/CNPJ</TableCell>
              <TableCell align="left">Nascimento</TableCell>
              <TableCell align="left">Função</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {accountables.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  {row.fullname}
                </TableCell>
                <TableCell align="left">{row.identifier}</TableCell>
                <TableCell align="left">{row.birthdate?new Date(row.birthdate).toLocaleDateString('pt-br',{style: 'currency', currency: 'BRL'}):null}</TableCell>
                <TableCell align="left">{row.type}</TableCell>
                <TableCell align="left">{row.status["name"]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
      </Grid>
    </Paper>
  </Card>
  );
}


function Accordions() {
  let { getApi,admPostApi,admGetApi, user } = useAuth();

let { bsaccountid } = useParams();
const [listDocuments, setListDocuments] = React.useState([]);
const [loadingList, setLoadingList] = React.useState(true);


const [totalDocs, setTotalDocs] = React.useState();


const [bsAccount, setBsAccount] = React.useState([]);
const [operator, setOperator] = React.useState([]);
const [accountables, setAccountables] = React.useState([]);

const [contacts, setContacts] = React.useState([]);


  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);


useEffect(() => {
  LoadBsAccount();
  LoadDocuments();

}, []);
const LoadDocuments = async () => {
    
  setListDocuments([]);
  setLoadingList(true);
  let response = await admPostApi("/list/documents", {
    business_account_id:bsaccountid,
    status:-1,
    minimal_data:false

  });
  console.log(response.lista)
  
  setListDocuments(response.lista);
  setTotalDocs(response.total);
  setLoadingList(false);

};

const LoadBsAccount = async () => {
    
  setBsAccount([]);
  let response = await admGetApi("/bsaccount/"+bsaccountid, {

  });
  console.log(response)
  
  setBsAccount(response.business_account);
  setOperator(response.operator);
  setAccountables(response.accountables);
  setContacts(response.business_account.contacts);

};


  return (
    <React.Fragment>
      <Helmet title="Análise de Conformidade" />
      <Typography variant="h3" gutterBottom display="inline">
        Análise de Conformidade
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link component={NavLink} to="#">
          Contas de Negócios
        </Link>
        <Typography>Análise de Conformidade</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      {user &&
        user.id==1 &&
            <Button onClick={handleOpen}>Atualizar situação cadastral</Button>
      }

      <Grid container spacing={3} >
        <Grid item xs={8} md={3}>

          <Operator operator={operator}/>
        </Grid>
        <Grid item xs={8} md={6}>
          <BSAccountComponent bsAccount={bsAccount}/>
        </Grid>
        <Grid item xs={8} md={9}>
         <BSContactsComponent contacts={contacts}/>
       </Grid>
        <Grid item xs={8} md={9}>
          <BSAccountableComponent accountables={accountables}/>
        </Grid>
      </Grid>
      
      <Grid container spacing={6}>
        <Grid item xs={12} md={4}>
       
        <ListaDocumentId totalDocs={totalDocs} listDocuments={listDocuments.filter(function(doc) {
        return doc.document_type["id"]==1
        })} 
          />
        
        </Grid>
        <Grid item xs={12} md={4}>
        <ListDocsSelfie totalDocs={totalDocs} listDocuments={listDocuments.filter(function(doc) {
        return doc.document_type["id"]==2
        })} />
        </Grid>

        <Grid item xs={12} md={4}>
        <ListDocsCNPJ  totalDocs={totalDocs} listDocuments={listDocuments.filter(function(doc) {
        return doc.document_type["id"]==3
        })} />
        </Grid>

        <Grid item xs={12} md={4}>
        <ListOutrosDocs  totalDocs={totalDocs} listDocuments={listDocuments.filter(function(doc) {
        return doc.document_type["id"]>3
        })} />
        </Grid>


        

      </Grid>
      {bsAccount &&
            <ModalAproveAccount bsAccount={bsAccount} open={open} handleOpen={handleOpen}></ModalAproveAccount>
      }
      {bsAccount.created_at &&
            <RelatedAccountsByCreated bsAccount={bsAccount} open={open} handleOpen={handleOpen}></RelatedAccountsByCreated>
      }

    </React.Fragment>
  );
}

function RelatedAccountsByCreated(props) {

  const [relatedAccounts, setRelatedAccounts] = React.useState([]);
  let { getApi,admPostApi,admGetApi } = useAuth();

  const {bsAccount} = props;

  



  useEffect(() => {
   


    if(bsAccount){
      let start=new Date(bsAccount.created_at);
      let end=new Date(bsAccount.created_at);
      start.setDate(
        start.getDate() - 1
      )

      end.setDate(
        end.getDate() + 1
      )
      LoadRelatedAccounts(start,end);

    }

  }, []);



  const LoadRelatedAccounts = async (created_start,created_end) => {
    if(!created_start){
      return;
    }
    setRelatedAccounts([]);
    let response = await admPostApi("/bsaccount/list", {
      
        "limit": 100,
        "page": 0,
        "minimal_data": false,
        "queryString": {
          "BusinessAccount": {
              "filterdate":1,
              "filterdatevalue":{
                  "start": created_start,
                  "end": created_end
              }
          }
        }
      

    });
    
    setRelatedAccounts(response.lista);

  };




  return (
    <Card mb={6}>
    <CardContent pb={1}>
      <Typography variant="h6" gutterBottom>
      Contas recem-criadas no periodo - {new Date(bsAccount.created_at).toLocaleDateString()+" "+new Date(bsAccount.created_at).toLocaleTimeString()}
      </Typography>
    </CardContent>
    <Paper>
      <Grid container sx={{overflow:"auto"}}>
        <Grid item md={10} xs="6" >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Razão Social</TableCell>
              <TableCell align="left">Nome Fantasia</TableCell>
              <TableCell align="left">CPF/CNPJ</TableCell>
              <TableCell align="left">Atividade Principal</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">Criado em:</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {relatedAccounts.map((row) => (
            row.business_account.id!=bsAccount.id && 
              <TableRow key={row.business_account.id}>
                <TableCell component="th" scope="row">
                  {row.business_account.id}
                </TableCell>
                <TableCell align="left">{row.business_account.company_name}</TableCell>
                <TableCell align="left">{row.business_account.fantasy_name}</TableCell>
                <TableCell align="left">{row.business_account.identifier}</TableCell>
                <TableCell align="left">{row.business_account.activity.name}</TableCell>
                <TableCell align="left">{row.business_account.account_status.extend_name}</TableCell>
                <TableCell align="left">{new Date(row.business_account.created_at).toLocaleDateString()+" "+new Date(row.business_account.created_at).toLocaleTimeString()}</TableCell>
                <TableCell align="left">
                <Link  href={"/digital-account/bs-account/aprovacao/"+row.business_account.id}>
                <Button
                  variant="contained"
                  size="small"
                >
                  Visualizar
                </Button>
                </Link>
                </TableCell>

                
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </Grid>
      </Grid>
    </Paper>
  </Card>
  )
}

export default Accordions;
